#works{padding-top:6em;}
h2{margin: 0;line-height: 1em;}
h2{font:300 2.5em;margin-bottom: 1.5em; color:#fff;}

#works figcaption a{border:1px solid #fff;margin-top: 1em;display: inline-block;color: #fff;padding: 0 2em;}
#works figcaption a:hover{text-decoration: none;padding: 0 3em;}
@media (max-width: 900px) {
	figure.effect-chico figcaption {padding: 2em;}	
	figure.effect-oscar figcaption {padding: 20%;}
	figure.effect-chico figcaption::before{top: 10px;right: 10px;bottom: 10px;left: 10px;}
	.grid figure.effect-chico p{font-size: 0.6em;line-height: 1.5em;}	
}


@media (max-width: 767px) {
	#works {padding-top: 5em;}
	.grid.team div{margin: 0;padding: 0;}
	figure.effect-chico figcaption {padding: 1.5em;}
}
.clearfix:before,
.clearfix:after {
  display: table;
}

.clearfix:after {
  clear: both;
} 
@media (max-width: 1200px) {
	.grid figure h2{font: 200 2.5em;}
	figure.effect-oscar figcaption{padding: 12%;}
	figure.effect-oscar figcaption::before,figure.effect-chico figcaption::before{top: 20px;right: 20px;bottom: 20px;left: 20px;}
	.partners img{margin: 0 25px 25px 0;}
}
