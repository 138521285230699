h3 {
    color: white;
    padding: 0px;
}
li {
    color: white;
    padding: 0px;
    font-weight: bold;
}
.aboutcard{
    margin: 45px 0px 75px 0px;
}
.aboutcard h3{
    line-height: 10px;
}
li {
    list-style: lower-alpha;
    font-size: 15px;
}
ol {
    padding-left: 20px;
}
@media (max-width: 767px) {
	.about-detail{ line-height: 25px;}
}