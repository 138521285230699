.ambienceinsidehead {
    font-size: 80%;
    font-weight: 700;
}
.red {
    color: #ed3237 !important;
}
h2 {
    font-size: 250%;
    font-weight: 300;
    text-align: center;
    margin-bottom: 50px;
}
.inside_view h2 {
    line-height: 1;
}
.inside_view {
    padding-top: 50px;
    text-align: center;
    padding-bottom: 50px;
}
.modal {
    background: rgba(0, 0, 0, 0.6);
  }
  .modal-container {
    position: absolute;
    width: 60%;
    background: #ffffff;
    left: 25%;
    top: 30%;
    padding: 20px;
  }